import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import './../assets/base.css';
import "./../assets/styles.css"
import React, {Suspense, lazy} from 'react';
import Loader from './../Layout/AppMain/loader'

//pages
const Proyek = lazy(() => import('./Proyek'));
const Admin = lazy(() => import('./Admin'));
const Direktur = lazy(() => import('./Direktur'));
const Account = lazy(() => import('./Account'));

const Dashboard = ()=>{
	return(
		<div>
			{/* bagian proyek */}
	      <Suspense fallback={<Loader/>}>
	          <Route path="/proyek" component={Proyek}/>
	      </Suspense>

	   	{/* bagian admin */}
	      <Suspense fallback={<Loader/>}>
	          <Route path="/admin" component={Admin}/>
	      </Suspense>

	   	{/* bagian direktur */}
	      <Suspense fallback={<Loader/>}>
	          <Route path="/direktur" component={Direktur}/>
	      </Suspense>

	   	{/* ubah akun */}
	      <Suspense fallback={<Loader/>}>
	          <Route path="/change_akun" component={Account}/>
	      </Suspense>

	      <Route exact path="/" render={() => (
             <Redirect to="/login"/>
         )}/>
      </div>
	)
}

const mapStateToProp = state => ({
    isLogin:state.AppData.isLogin,
    userLogin:state.AppData.userLogin,

});

export default connect(mapStateToProp)(Dashboard)