export const SET_ISLOGIN = 'APP_DATA/SET_ISLOGIN';
export const SET_USERLOGIN = 'APP_DATA/SET_USERLOGIN';
export const SET_HOMEPAGE = 'APP_DATA/SET_HOMEPAGE';
export const SET_UPDATEPAKETCOUNT = 'APP_DATA/SET_UPDATEPAKETCOUNT';

export const setIsLogin = isLogin => ({
    type: SET_ISLOGIN,
    isLogin
});

export const setUserLogin = userLogin => ({
    type: SET_USERLOGIN,
    userLogin
});

export const setHomePage = homePage => ({
    type: SET_HOMEPAGE,
    homePage
});

export const setUpdatePaketCount = updatePaketCount => ({
    type: SET_UPDATEPAKETCOUNT,
    updatePaketCount
});

export default function reducer(state = {
    isLogin: false,
    userLogin:{},
    homePage:"http://localhost/hk-api-lama",
    updatePaketCount:0
}, action) {
    switch (action.type) {
        
        case SET_ISLOGIN:
            return {
                ...state,
                isLogin: action.isLogin
            };

        case SET_USERLOGIN:
            return {
                ...state,
                userLogin: action.userLogin
            };

        case SET_HOMEPAGE:
            return {
                ...state,
                homePage: action.homePage
            };
        
        case SET_UPDATEPAKETCOUNT:
            return {
                ...state,
                updatePaketCount: action.updatePaketCount
            };
    }
    return state;
}