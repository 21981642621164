import React,{Fragment} from 'react'
import api from '../config/api'
import {connect} from 'react-redux';
import {
    toast,
    Slide
} from 'react-toastify';

//redux
import{setIsLogin, setUserLogin} from '../reducers/AppData'

class LoginForm extends React.Component{
    state={
        username:'',
        password:'',
        is_loading:false
    }

    handleChange = (event)=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    }

    notifyLoginFailed=()=>this.toastId = toast("Login Gagal.! Username atau Password Salah.", {
        transition: Slide,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-right',
        type: 'error'
    })

    login = async (event)=>{
        event.preventDefault();

        this.setState({
            is_loading:true
        })

        const {username, password}=this.state;
        const {setIsLogin, setUserLogin}=this.props;
        await api.post("/auth/login", {
            username:username,
            password:password
        })
        .then(response => {
            if(response.data.success){
                setUserLogin(response.data.user)
                setIsLogin(true)
                localStorage.setItem("user_data", JSON.stringify(response.data.user));

                let redirect
                if(response.data.user.level==="admin"){
                    redirect="/admin/proyek"
                }
                else if(response.data.user.level==="direktur"){
                    redirect="/direktur/proyek"
                }
                else{
                    redirect="/proyek/"+response.data.user.id_proyek+"/semua_paket"
                }
                this.props.history.push("/proyek/redirect/?to="+redirect)
            }
            else{
                setIsLogin(false)
                this.notifyLoginFailed()
                this.setState({
                    password:""
                })
            }
        })
        .then(()=>{
            this.setState({
                is_loading:false
            })
        })
    }

    render(){
        return(
            <Fragment>
                <div id='container-login'>
                    </div>
                    <div  class="d-flex justify-content-center">
                        <div id='login-wrapper'>
                            <div id='login-icon'>
                                <img src="/images/mboh.png" className="img-logo"/>
                            </div>
                            <form id="login-form" onSubmit={this.login}>
                                <div class="d-flex justify-contetnt-between login-input">
                                    <svg class="login__icon name svg-icon">
                                       <path d="M0,20 a10,8 0 0,1 20,0z M10,0 a4,4 0 0,1 0,8 a4,4 0 0,1 0,-8" />
                                    </svg>
                                    <input type='text' class="form-control" placeholder="Username" name="username" required="" value={this.state.username} onChange={this.handleChange} />
                                </div>
                                <div class="d-flex justify-contetnt-between login-input mt-4">
                                    <svg class="login__icon pass svg-icon">
                                        <path d="M0,20 20,20 20,8 0,8z M10,13 10,16z M4,8 a6,8 0 0,1 12,0" />
                                    </svg>
                                    <input type='password' class="form-control" placeholder="Password" name="password" required="" value={this.state.password} onChange={this.handleChange} />
                                </div>
                                <button type="submit" class="btn btn-login" disabled={this.state.is_loading?true:false}>
                                    <span>Login</span>
                                </button>
                            </form>
                        </div>
                    </div>
            </Fragment>
        )
    }
}

const mapStateToProp = state => ({
    isLogin:state.AppData.isLogin,
    userLogin:state.AppData.userLogin,
});

const mapDispatchToProps = dispatch => ({
    setIsLogin: value => dispatch(setIsLogin(value)),
    setUserLogin: value => dispatch(setUserLogin(value)),
});

export default connect(mapStateToProp, mapDispatchToProps)(LoginForm)