import React, {Fragment, Suspense} from 'react';
import {connect} from 'react-redux'; 
import cx from 'classnames';
import {withRouter, Route} from 'react-router-dom';
import api from '../config/api'

import {
    ToastContainer,
} from 'react-toastify';

import Dashboard from './dashboard';
import LoginForm from './login'
import Loader from './../Layout/AppMain/loader'

import {setUserLogin, setIsLogin} from '../reducers/AppData'

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closedSmallerSidebar: false,
            width: undefined
        }; 

    }

    componentDidMount(){
        const {setUserLogin, setIsLogin}=this.props

        const user_data=localStorage.getItem("user_data");
        if(user_data!==null){
            setIsLogin(true)
            setUserLogin(JSON.parse(user_data))
        }

        api.post("/admin/admin/pengaturan/", {
            token:this.props.userLogin.token
        })
        .then(response => {
            if(response.data.status==="success"){
                document.title=response.data.pengaturan.perusahaan
            }
        })
    }

    render() {
        const { width } = this.state;

        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            isLogin
        } = this.props;

        return (
            <Fragment>
                <div className={cx(
                    "app-container app-theme-" + colorScheme,
                    {'fixed-header': enableFixedHeader},
                    {'fixed-sidebar': enableFixedSidebar || width < 1250},
                    {'fixed-footer': enableFixedFooter},
                    {'closed-sidebar': enableClosedSidebar || width < 1250},
                    {'closed-sidebar-mobile': closedSmallerSidebar || width < 1250},
                    {'sidebar-mobile-open': enableMobileMenu},
                )}>
                    {/* app content */}
                    {isLogin?<Dashboard/>:<LoginForm {...this.props}/>}

                    {/* login administrator */}
                    <Suspense fallback={<Loader/>}>
                         <Route path="/login" component={LoginForm}/>
                    </Suspense>

                    {/* toast container */}
                    <ToastContainer className="toast-container"/>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

    isLogin:state.AppData.isLogin,
    userLogin:state.AppData.userLogin,

});

const mapDispatchToProps = dispatch => ({
    setIsLogin: value => dispatch(setIsLogin(value)),
    setUserLogin: value => dispatch(setUserLogin(value)),
});


export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Main));