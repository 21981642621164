import React from 'react';
const Loader = () => {
    return (
        <div className="loader-container">
            <div className="loader-container-inner">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
};

export default Loader;